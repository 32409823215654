<template>
  <div :class="`m-3 p-2 wrapper wrapper--default`">
    <Loading v-if="loading" />
    <div v-else>
      <div class="w100 flexbox">
        <h3 class="w100">Algemeen</h3>
        <hr class="m2 programs_hr w100" />
        <ProgramCard
          :link="`https://oneworld.network.kleyn.com:1081/jde/E1Menu.maf`"
          title="OneWorld"
          :icon="`https://apps.kleyn.com/images/oneworld.png`"
        />
        <ProgramCard
          :link="`https://kleyn.crm4.dynamics.com/`"
          title="CRM"
          :icon="`https://apps.kleyn.com/images/dynamicscrm.png`"
        />
        <ProgramCard
          :link="`https://www.youtube.com/playlist?list=PLVVpi5puQMT4zQeL_VU3kUvkB1qSdwnnm`"
          title="CRM instructievideos"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/video-tutorial.png`"
        />
        <ProgramCard
          :link="`https://kleyn.crm4.dynamics.com/main.aspx?appid=a1971671-a4b2-e911-a849-000d3a2bd64e&pagetype=entitylist&etn=incident&viewid=00000000-0000-0000-00aa-000010001030&viewType=1039`"
          title="CRM Cases"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/wrench.png`"
        />
        <ProgramCard
          :link="`https://kleyngroup.atlassian.net/servicedesk/customer/portal/1`"
          title="Helpdesk ICT"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/it_support.png`"
        />
        <ProgramCard
          :link="`https://app.powerbi.com`"
          title="PowerBI Portaal"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/powerbi.png`"
        />
        <ProgramCard
          :link="`https://learning.awaretrain.com/nl/9DLEJE1zxY/login`"
          title="AwareTrain"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/awaretrain.png`"
        />
        <ProgramCard
          :link="`https://myaccount.microsoft.com`"
          title="Wachtwoord veranderen"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/wachtwoord_veranderen.png`"
        />
        <ProgramCard
          :link="`https://idp.afasonline.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3D55f6be51-7326-4e9b-a652-bebd944d5b81%26response_type%3Dcode%26scope%3Dopenid%2520email%2520offline_access%26redirect_uri%3Dhttps%253A%252F%252Fsts.afasonline.com%252Fsignin-oidc%26state%3DQ2ZESjhJc2dYZGgxN1pOTG5EZXNFQ2hwQ0NWblIwZzNzQUMxRzJGQ015VGFUSGlRT2dkTFFfSk14aW1rMW9obkc2NG94V0FmaVhCbmRzN1djd2NQMGU3RjhLV0ZEN2E2LWhZOG1MYWk0NVBxTDZNS1dUWUU0NU85ZXA5NHdZM0lTV09jdnJ6c2s5RTJvOHlybjNFMkR2YURvbFNPdnpuUlNGbnpWMmxtSlFwaU9ZMXVnRTZkTmNfWGJoRTNFa3IxSC1ZaDBkUTNacGhPV0Vybjh3V2FKR1JnQ19hNlJkb21XRU1XVFpodjhWV1ZzQjRWV2tuQi1BUDJjako2R0lfTzZSMTV2UXFkQVI2WjVhbUpKQU5vRXJvUGxrUmU2VUkxM1U1YUxLMHE5bGhsdTdkQg%253D%253D%26nonce%3D926fd6c3d2066c5af93498a2d41853907980adb274c3475d7753062478bea0ae%26response_mode%3Dquery%26ui_locales%3Den#`"
          title="AFAS"
          :icon="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/afaslogo.webp`"
        />
      </div>
      <div v-for="(group, key) in programs" :key="key" class="flexbox w100">
        <div class="w100">
          <h3>{{ key }}</h3>
        </div>
        <hr class="w100 m0 programs_hr" />
        <div class="flex _row w100">
          <ProgramCard
            v-for="(program, key) in group"
            :key="key"
            :link="`kleyn:${program.Tag}`"
            :title="`${program.Titel}`"
            :icon="`https://apps.kleyn.com/images/${program.Icon}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import ProgramCard from "@/components/ProgramCard.vue";

export default {
  components: { Loading, ProgramCard },
  data: () => ({
    programs: null,
    loading: true,
    name: null,
  }),
  created() {
    this.name = this.msalInstance.getAllAccounts()[0].idTokenClaims.preferred_username;
    this.getData();
  },
  methods: {
    getData() {
      request(`programs/${this.name}`, "GET").then(({ programs }) => {
        this.programs = programs;
        this.loading = false;
      });
    },
  },
};
</script>
